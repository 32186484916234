<template>
  <div class="add-popup">
    <div class="add-form">
      <el-form ref="form" :model="form" label-width="160px">
        <el-form-item label="联系人" required style="margin-left:-10px">
          <el-select
            style="width:270px;margin-left:10px"
            v-model="form.contactId"
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userName"
              :key="item.id"
              :label="item.contactName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="往来时间">
          <el-date-picker
            v-model="form.intercourseTime"
            type="datetime"
            placeholder="选择日期时间"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width:270px;"
            :picker-options="expireTimeOption"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="往来标题">
          <el-input
            v-model="form.intercourseTitle"
            placeholder="请输入往来标题"
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系方式" required style="">
          <el-select
            v-model="form.contactWay"
            size="small"
            style="width:270px"
            placeholder="请选择联系方式"
            clearable
          >
            <el-option label="电话" :value="1"></el-option>
            <el-option label="微信" :value="2"></el-option>
            <el-option label="邮箱" :value="3"></el-option>
            <el-option label="QQ" :value="4"></el-option>
            <el-option label="短信" :value="5"></el-option>
            <el-option label="拜访" :value="6"></el-option>
            <el-option label="官网表单" :value="8"></el-option>
            <el-option label="座机" :value="9"></el-option>
            <el-option label="其他" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="往来内容" required>
          <el-input
            style="width:270px"
            type="textarea"
            placeholder="请输入往来内容"
            v-model="form.intercourseContent"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { addDealings } from "@/api/cs/workOrder.js";
export default {
  data() {
    return {
      form: {
        intercourseTime: this.$handTime.getFormatDate(),
        contactWay: "",
        intercourseTitle: "",
        name: sessionStorage.getItem("nickName"),
        intercourseContent: "",
        remark: "",
      },
      userName: [],
      expireTimeOption: {
        disabledDate(date) {
          // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() >= Date.now();
        },
      },
    };
  },

  methods: {
    getData(row) {
      this.userName = row.customerContactList;
    },
    onSubmit() {
      if (this.form.contactId == "") {
        this.$message.error("请选择联系人");
      } else if (this.form.contactWay == "") {
        this.$message.error("请选择联系方式");
      } else if (this.form.intercourseContent == "") {
        this.$message.error("请输入往来内容");
      } else {
        this.form.name = sessionStorage.getItem("nickName");
        this.form.describeList = [];
        this.form.workOrderId = this.$formDetails.state.data.id;
        let data = {
          param: this.form,
        };
        addDealings(data).then((res) => {
          if (res.code == 200) {
            this.$message.success("添加成功");
          }
          this.onClose();
        });
      }
    },
    onClose() {
      this.$emit("close");
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
      this.form.intercourseTime = this.$handTime.getFormatDate();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.add-popup {
  width: 100%;

  .add-form {
    padding: 30px 0 70px 0;
  }
  .el-input {
    width: 270px;
    line-height: 32px;
  }
  .but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 72px;
    }
  }
}
</style>
