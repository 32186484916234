<template>
  <div class="details">
    <div class="information">
      <div class="title">
        <p><span></span>联系人信息</p>
        <el-button type="primary" @click="onUpData" size="mini" v-show="type"
          >编辑</el-button
        >
      </div>
      <div class="content">
        <Information ref="information" @close="onClose" />
      </div>
    </div>
    <div class="aggregate">
      <div class="tab-box">
        <div class="tab">
          <span></span>
          <p @click="onSwitch(1)" :class="index == 1 ? 'isColor' : ''">
            工单
          </p>
          <p @click="onSwitch(2)" :class="index == 2 ? 'isColor' : ''">
            往来记录
          </p>

          <p @click="onSwitch(3)" :class="index == 3 ? 'isColor' : ''">
            历史
          </p>
        </div>
        <el-button
          type="primary"
          @click="onAddDealings"
          size="mini"
          v-show="type"
          >新增往来</el-button
        >
      </div>
      <div class="content-form-box">
        <Order ref="order" v-show="index == 1" />
        <Dealings ref="dealings" v-show="index == 2" />
        <History ref="history" v-show="index == 3" />
      </div>
    </div>
    <!-- 添加往来 -->
    <el-dialog
      :visible.sync="dialogDealings"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          添加往来
        </div>
      </template>
      <AddDealings @close="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import Information from "./information/information.vue";
import Order from "./order/order.vue";
import Dealings from "./dealings/dealings.vue";
import History from "./history/history.vue";
import AddDealings from "./dealings/addDealings.vue";
export default {
  components: {
    Information,
    Order,
    Dealings,
    History,
    AddDealings,
  },
  data() {
    return {
      row: "",
      btnP: {},
      type: false,
      index: 1,
      dialogDealings: false,
    };
  },
  methods: {
    getData(row, btnP, type) {
      this.btnP = btnP;
      this.type = type;
      this.row = row;
      setTimeout(() => {
        this.$refs.information.getCustomerList(row);
        this.$refs.order.getData(row);
      });
    },
    onUpData() {
      setTimeout(() => {
        this.$refs.information.getUpData(this.type);
      });
    },
    onSwitch(i) {
      this.index = i;
      if (i == 1) {
        this.$refs.order.getData(this.row);
      } else if (i == 2) {
        this.$refs.dealings.getData(this.row, this.btnP);
      } else if (i == 3) {
        this.$refs.history.getData(this.row);
      }
    },
    onAddDealings() {
      this.dialogDealings = true;
    },
    handleClose() {
      this.dialogDealings = false;
      this.onSwitch(2);
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.details {
  width: 100%;
  height: 100%;
  display: flex;
  background: #f0f2f4;
  .information {
    width: 412px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    .title {
      height: 59px;
      border-bottom: 1px dashed #e5e5e5;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      color: #333333;
      p {
        display: flex;
        align-items: center;
      }
      span {
        display: block;
        width: 2px;
        height: 16px;
        background: #2370eb;
        border-radius: 1px 1px 0px 0px;
        margin-right: 12px;
      }
      button {
        background: #fff;
        color: #ff760f;
        border-color: #ff760f;
        margin-right: 8px;
      }
    }
    .content {
      flex: 1;
      overflow: auto;
    }
  }
  .aggregate {
    flex: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .tab-box {
      display: flex;
      align-items: center;
      height: 59px;
      border-bottom: 1px dashed #eee;
      overflow: auto;
      flex-direction: row;
      justify-content: space-between;
      .tab {
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 2px;
          height: 16px;
          background: #2370eb;
          border-radius: 1px 1px 0px 0px;
          margin-right: 12px;
        }
        p {
          cursor: pointer;
          font-weight: 500;
          color: #666666;
          font-size: 14px;
          padding: 0 16px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          overflow: hidden;
          width: 60px;
          border: 1px solid #e5e5e5;
          border-radius: 12px;
          /*文本不会换行*/
          white-space: nowrap;
          /*当文本溢出包含元素时，以省略号表示超出的文本*/
          text-overflow: ellipsis;
          margin-right: 10px;
        }
        .isColor {
          color: #2370eb;
          position: relative;
          border-color: #2370eb;
        }
      }
      button {
        background: #fff;
        color: #2370eb;
        border-color: #2370eb;
        margin-right: 8px;
      }
    }
    .content-form-box {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
