<template>
    <div class="transfer">
        <el-form
            ref="form"
            :model="form"
            style="padding-left: 43px; flex: 1; margin-top: 50px"
            label-width="100px"
            label-position="left"
        >
            <el-form-item label="选择客户" required>
                <el-select
                    style="width: 270px"
                    v-model="form.customerId"
                    size="small"
                    clearable
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in customerList"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 32px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { customerList } from '@/api/customer/customer.js';
import { contactEdit } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            form: {},
            customerList: [],
        };
    },
    methods: {
        // 客户列表
        getCustomerList(item) {
            this.form = item;
            this.form.customerId = item.customerBO.id;
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };
            data.param.adminId = sessionStorage.getItem('adminId');
            customerList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerList = res.data.list;
                }
            });
        },
        onClose() {
            this.$emit('close');
            this.form = {};
        },
        onSubmit() {
            if (!this.form.customerId) {
                return this.$message.error('请选择客户');
            }
            let data = {
                param: {
                    customerContactDO: this.form,
                },
            };
            contactEdit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('关联成功');
                }
                this.onClose();
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.transfer {
    display: flex;
    flex-direction: column;
    height: 300px;
}
.bottom-but {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    margin-top: 16px;
    text-align: right;
    button {
        width: 90px;
    }
}
</style>
