<template>
    <div class="content_form">
        <div style="flex:1;overflow: auto;">
            <div class="form">
                <el-form label-width="90px" label-position="left" size="small">
                    <el-form-item label="问题描述">
                        <span>{{
                            data.workOrderDescribe
                                ? data.workOrderDescribe
                                : '- -'
                        }}</span>
                    </el-form-item>
                    <el-form-item label="附件">
                        <div class="annex">
                            <div
                                class="annexList"
                                v-for="item in data.workOrderAttachmentList"
                            >
                                <el-image
                                    v-if="
                                        item.attachmentUrl.indexOf('.jpg') !=
                                            -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.png'
                                            ) != -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.gif'
                                            ) != -1
                                    "
                                    style="width:64px; height:64px"
                                    :src="item.attachmentUrl"
                                    :preview-src-list="[item.attachmentUrl]"
                                >
                                </el-image>
                                <span
                                    class="pdf"
                                    v-if="
                                        item.attachmentUrl.indexOf('.pdf') !=
                                            -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.PDF'
                                            ) != -1
                                    "
                                    @click="onPreview(item.attachmentUrl)"
                                    style="cursor: pointer;"
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span
                                    class="word"
                                    v-if="
                                        item.attachmentUrl.indexOf('.doc') != -1
                                    "
                                    @click="onPreview(item.attachmentUrl)"
                                    style="cursor: pointer;"
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                                <span
                                    v-if="
                                        item.attachmentUrl.indexOf('.mp4') !=
                                            -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.MP4'
                                            ) != -1
                                    "
                                    @click="onPreview(item.attachmentUrl)"
                                    style="cursor: pointer;"
                                >
                                    <img
                                        style="width:64px"
                                        src="@/assets/video.png"
                                        alt=""
                                    />
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item
                        label="关联试用"
                        v-if="data.categoryType == 1"
                    >
                        <div class="relation">
                            <span>{{
                                trialData.companyName
                                    ? trialData.companyName
                                    : '- -'
                            }}</span>
                            <el-button
                                type="primary"
                                size="mini"
                                style="background:none;norder-color:#2370EB;color:#2370EB;margin-right:10px"
                                @click="onTrialDetail(trialData)"
                                >详情</el-button
                            >
                        </div>
                    </el-form-item>
                    <el-form-item
                        label="关联订单"
                        v-if="data.categoryType == 2"
                    >
                        <div class="relation">
                            <span>{{
                                $tableDataHandle.productCategory(
                                    orderData.productCategory
                                )
                            }}</span>
                            <el-button
                                type="primary"
                                size="mini"
                                style="background:none;norder-color:#2370EB;color:#2370EB;margin-right:10px"
                                @click="onOrderDetail(orderData)"
                                >详情</el-button
                            >
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="opinion">
                <div class="title_box">
                    <p class="title"><span></span>处理意见</p>
                    <span>
                        <el-button
                            v-if="btnP.dealings"
                            type="primary"
                            size="mini"
                            style="background:none;norder-color:#2370EB;color:#2370EB"
                            @click="onAddDealings"
                            >新增往来</el-button
                        >
                    </span>
                </div>

                <el-input
                    type="textarea"
                    style="border:none"
                    :rows="10"
                    placeholder="请输入内容"
                    v-model="textarea"
                >
                </el-input>
            </div>
        </div>
        <!-- 订单详情 -->
        <el-drawer
            :visible.sync="drawerOrder"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    订单详情
                </div>
            </template>
            <OrderDetails @close="handleClose" ref="orderdetails" />
        </el-drawer>
        <!-- 试用详情 -->
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    试用详情
                </div>
            </template>
            <Details @close="handleClose" ref="trial" />
        </el-drawer>
    </div>
</template>
<script>
import { getCustomerId } from '@/api/user/ae/customer';
import { tmTrial } from '@/api/user/business/customer';
import { orderList } from '@/api/cs/workOrder.js';
import OrderDetails from '../../../../order/details.vue';
import Details from './trial.vue';
export default {
    components: {
        OrderDetails,
        Details,
    },
    data() {
        return {
            textarea: '',
            data: {},
            btnP: {},
            trialData: '',
            orderData: '',
            drawer: false,
            drawerOrder: false,
            direction: 'rtl',
        };
    },
    methods: {
        getData(data, btnP) {
            this.btnP = btnP;
            this.data = data;
            this.textarea = data.handleOpinion;
            if (data.categoryType == 1) {
                this.getTrial();
            } else if (data.categoryType == 2) {
                this.getOrder();
            }
        },
        onAddDealings() {
            this.$emit('addDealings');
        },
        getTrial() {
            let data = {
                param: {
                    id: this.data.categoryId,
                },
            };
            getCustomerId(data).then((res) => {
                this.trialData = res.data;
            });
        },
        getOrder() {
            let data = {
                param: {
                    id: this.data.categoryId,
                },
            };
            orderList(data).then((res) => {
                this.orderData = res.data.list[0];
            });
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
        onTrialDetail(row) {
            // console.log(row);
            this.drawer = true;
            let data = {
                param: {
                    customerId: this.data.categoryId,
                },
            };
            tmTrial(data).then((res) => {
                this.drawerTitle = '试用详情';
                this.drawerTrial = true;
                setTimeout(() => {
                    this.$refs.trial.echoData(res.data.list[0]);
                }, 50);
            });
        },
        onOrderDetail() {
            this.drawerOrder = true;
            setTimeout(() => {
                // console.log(this.orderData);
                this.$refs.orderdetails.getData(this.orderData);
            }, 50);
        },
        handleClose() {
            this.drawerOrder = false;
            this.drawer = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.content_form {
    height: 100%;
    display: flex;
    flex-direction: column;
    .form {
        padding-left: 25px;
        border-bottom: 1px solid #e5e5e5;
    }
    .opinion {
        .title_box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px dashed #e5e5e5;
            margin: 0 10px;
            align-items: center;
        }
        .title {
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333333;
            span {
                display: block;
                width: 2px;
                height: 16px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
                margin-right: 12px;
            }
        }
    }
}

/deep/ .el-textarea__inner {
    border: none !important;
}
.annexList {
    margin-right: 10px;
    margin-bottom: 5px;
}
.annex {
    display: flex;
    align-items: flex-start;
}
.relation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.video {
    width: 64px;
    line-height: 64px;
    display: block;
    background: #cfcfcf;
    text-align: center;
}
</style>
