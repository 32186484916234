<template>
  <div class="form">
    <div class="form_box">
      <el-form label-width="85px" label-position="left" size="small">
        <el-form-item label="公司名称" required>
          <el-input
            :disabled="!disabled"
            v-model="customerDO.companyName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="域名" required>
          <el-input
            :disabled="!disabled"
            v-model="customerDO.companyDomain"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <template v-for="(item, index) in customerDomainList">
          <div :key="index">
            <el-form-item label="关联域名">
              <el-input
                :disabled="!disabled"
                style="width:230px"
                v-model="item.domain"
                placeholder="关联域名"
                size="small"
              ></el-input>
              <el-button
                v-if="index == 0 && disabled"
                @click="addDomain"
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
              ></el-button>
              <el-button
                v-if="index > 0 && disabled"
                @click.prevent="removeDomain(index)"
                icon="el-icon-minus"
                type="primary"
                circle
                size="mini"
              ></el-button>
            </el-form-item>
          </div>
        </template>
        <el-form-item label="联系方式">
          <div
            v-if="customerDO.customerMainContactList"
            class="info contact_way_box"
            v-for="(item, index) in customerDO.customerMainContactList"
            :key="index"
          >
            <el-select
              :disabled="!disabled"
              v-model="item.contactWay"
              size="small"
              style="width:80px"
              clearable
              placeholder="方式"
            >
              <el-option label="电话" value="1"></el-option>
              <el-option label="微信" value="2"></el-option>
              <el-option label="邮箱" value="3"></el-option>
              <el-option label="QQ" value="4"></el-option>
              <el-option label="短信" value="5"></el-option>
              <el-option label="拜访" value="6"></el-option>
              <el-option label="其他" value="7"></el-option>
            </el-select>
            <el-input
              :disabled="!disabled"
              style="margin-left:5px;width:145px"
              v-model="item.contactContent"
              placeholder="请输入"
              size="small"
            ></el-input>
            <el-button
              v-if="index == 0 && disabled"
              @click="addContact"
              type="primary"
              icon="el-icon-plus"
              circle
              size="mini"
            ></el-button>
            <el-button
              v-if="index > 0 && disabled"
              @click.prevent="removeContact(index)"
              icon="el-icon-minus"
              type="primary"
              circle
              size="mini"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="原邮箱品牌">
          <el-select
            :disabled="!disabled"
            v-model="customerDO.primaryEmailBrand"
            size="small"
            style="width:230px"
            clearable
            placeholder="原邮箱品牌"
          >
            <el-option
              v-for="item in $searchForm.mailboxbrand()"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到期时间">
          <el-date-picker
            :disabled="!disabled"
            v-model="customerDO.customerExpireTime"
            type="date"
            value-format="yyyy-MM-dd hh:mm:ss"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户标签">
          <span
            :key="index"
            :class="item.category == 0 ? 'blue' : 'yellow'"
            class="labelList"
            v-for="(item, index) in $choiceLabel.state.labelList"
            >{{ item.tagName }}
            <span class="cancel" v-if="disabled" @click="onDeleteLabel(item)">
              <span class="el-icon-close"></span>
            </span>
          </span>
          <el-button
            v-if="disabled"
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click="onChoiceLabel"
            style="background:rgba(0, 0, 0, 0.02);border-color:rgba(0, 0, 0, 0.15);color:#000000;padding:9px"
          ></el-button>
        </el-form-item>
        <el-form-item label="所在地">
          <el-cascader
            :disabled="!disabled"
            placeholder="所在地"
            style="width:230px"
            clearable
            v-model="address"
            :options="$address.cascader()"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="邮箱版本" v-if="customerDO.primaryEmailBrand < 3">
          <el-select
            v-model="customerDO.emailVersionType"
            size="small"
            style="width:230px"
            clearable
            placeholder="邮箱版本"
            :disabled="!disabled"
          >
            <el-option
              label="全新用户"
              v-if="customerDO.primaryEmailBrand <= 1"
              :value="1"
            ></el-option>
            <el-option
              label="已有新版基础版"
              v-if="customerDO.primaryEmailBrand <= 1"
              :value="2"
            ></el-option>
            <el-option
              label="已有旧版基础版"
              v-if="customerDO.primaryEmailBrand <= 1"
              :value="3"
            ></el-option>
            <el-option
              label="阿里-标准版"
              v-if="customerDO.primaryEmailBrand >= 2"
              :value="4"
            ></el-option>
            <el-option
              label="阿里-尊享版"
              v-if="customerDO.primaryEmailBrand >= 2"
              :value="5"
            ></el-option>
            <el-option
              label="阿里-集团版"
              v-if="customerDO.primaryEmailBrand >= 2"
              :value="6"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="customerDO.primaryEmailBrand == 1"
          :label="
            customerDO.emailVersionType == 1
              ? '手机号'
              : customerDO.emailVersionType == 2
              ? '企业ID'
              : '管理员账号'
          "
        >
          <el-input
            :disabled="!disabled"
            v-model="customerDO.emailVersionTypeName"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属行业">
          <el-select
            :disabled="!disabled"
            v-model="customerDO.business"
            size="small"
            style="width:230px"
            clearable
            placeholder="所属行业"
          >
            <el-option
              v-for="item in $industry.industry()"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预计用户数">
          <el-input
            :disabled="!disabled"
            v-model="customerDO.number"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="预计年限">
          <el-input
            :disabled="!disabled"
            v-model="customerDO.term"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            :disabled="!disabled"
            v-model="customerDO.address"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系备注">
          <el-input
            :disabled="!disabled"
            v-model="customerDO.contactRemark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            :disabled="!disabled"
            type="textarea"
            style="width:230px"
            rows="6"
            placeholder="请输入"
            v-model="customerDO.remark"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="but" v-show="disabled">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
    <el-dialog
      :visible.sync="dialogChoice"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          选择标签
        </div>
      </template>
      <ChoiceLabel @close="handleClose" @addLabel="addLabel" :isGet="isGet" />
    </el-dialog>
    <el-dialog
      :visible.sync="dialogAddlabel"
      width="560px"
      :before-close="handleAddlabel"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          新增个人标签
        </div>
      </template>
      <AddLable @close="handleAddlabel" @addLabel="addLabel" />
    </el-dialog>
  </div>
</template>
<script>
import { relationList, relationDelete } from "@/api/user/label.js";
import ChoiceLabel from "../../../../../../components/choice-label/choiceLabel.vue";
import AddLable from "../../../../../../components/choice-label/addLabel.vue";
import { editCustomer } from "@/api/user/ae/customer";
export default {
  components: {
    ChoiceLabel,
    AddLable,
  },
  data() {
    return {
      disabled: true,
      customerDO: {
        customerMainContactList: [{ contactWay: 1 }],
      },
      // 客户标签列表
      customerTagList: [],
      // 客户域名列表
      customerDomainList: [{}],
      address: [],
      dialogChoice: false,
      dialogAddlabel: false,
      isGet: false,
      disabled: false,
    };
  },
  methods: {
    getData(row) {
      this.disabled = false;
      this.customerDO = row;
      if (
        this.customerDO.customerMainContactLis &&
        this.customerDO.customerMainContactList.length == 0
      ) {
        this.customerDO.customerMainContactList = [
          { contactWay: "", contactContent: "" },
        ];
      }
      if (
        this.customerDO.customerMainContactLis &&
        this.customerDO.customerMainContactList.length > 0
      ) {
        this.customerDO.customerMainContactList.forEach((item) => {
          item.contactWay += "";
        });
      }
      if (
        !this.customerDO.customerMainContactLis ||
        this.customerDO.customerMainContactList.length == 0
      ) {
        this.customerDO.customerMainContactList = [{ contactWay: 1 }];
      }
      if (this.customerDO.primaryEmailBrand == 0) {
        this.customerDO.primaryEmailBrand = "";
      }
      if (this.customerDO.emailVersionType == 0) {
        this.customerDO.emailVersionType = "";
      }
      if (this.customerDO.business == 0) {
        this.customerDO.business = "";
      }

      this.customerDomainList = [{}];
      if (
        this.customerDO.customerDomainList &&
        this.customerDO.customerDomainList.length > 0
      ) {
        this.customerDomainList = row.customerDomainList;
      }
      this.address = [
        String(this.customerDO.provinceId),
        String(this.customerDO.cityId),
      ];
      this.getLabelData();
    },
    getUpData(type) {
      this.disabled = type;
    },
    // 获取绑定标签
    getLabelData() {
      let data = {
        param: {
          bandingId: this.customerDO.id,
          bandingTableType: 2,
        },
      };
      relationList(data).then((res) => {
        this.$choiceLabel.commit("getLabelList", JSON.stringify(res.data.list));
      });
    },
    // 添加域名
    addDomain() {
      this.customerDomainList.push({});
    },
    // 删除域名
    removeDomain(index) {
      this.customerDomainList.splice(index, 1);
    },
    // 添加联系方式
    addContact() {
      this.customerDO.customerMainContactList.push({});
    },
    // 删除联系方式
    removeContact(index) {
      this.customerDO.customerMainContactList.splice(index, 1);
    },
    // 新增标签
    addLabel() {
      this.dialogChoice = false;
      this.dialogAddlabel = true;
    },
    // 选择标签
    onChoiceLabel() {
      this.dialogChoice = true;
      let that = this;
      setTimeout(() => {
        that.isGet = !that.isGet;
        this.$choiceLabel.commit("isMultiple", false);
        this.$choiceLabel.commit("getType", "2");
        this.$choiceLabel.commit("getBindingType", "2");
        this.$choiceLabel.commit("getIsDetails", true);
      }, 200);
    },
    // 移除标签
    onDeleteLabel(item) {
      this.$choiceLabel.commit("removeLabel", JSON.stringify(item));
      let data = {
        param: {
          id: item.id,
        },
      };
      relationDelete(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
        }
      });
    },
    handleClose() {
      this.dialogChoice = false;
    },
    handleAddlabel() {
      this.dialogAddlabel = false;
      this.dialogChoice = true;
      this.isGet = !this.isGet;
    },
    onSubmit() {
      if (this.address.length > 0) {
        this.customerDO.provinceId = this.address[0];
        this.customerDO.cityId = this.address[1];
      }
      if (this.customerDO.business && this.customerDO.business.length > 0) {
        this.customerDO.business = this.customerDO.business[0];
      }

      if (this.customerDO.companyName == "") {
        return this.$message.error("请输入公司名称");
      }

      this.customerDO.id = this.$formDetails.state.data.id;
      let data = {
        param: {
          customerDO: this.customerDO,
          customerDomainList: this.customerDomainList,
        },
      };
      editCustomer(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.$emit("close");
        }
      });
    },
    onClose() {
      this.$emit("close");
      this.$formDetails.commit("emptyData");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  .form_box {
    padding: 20px 30px;
    flex: 1;
    overflow: auto;
  }
  .el-input {
    width: 230px;
  }
  .el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    border: 2px solid #2370eb;
    margin-left: 12px;
  }
  .contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
  }
  .labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-top: 10px solid rgba(255, 255, 255, 0);
      border-right: 10px solid rgba(255, 255, 255, 0);
      border-bottom: 10px solid rgba(255, 255, 255, 0);
      border-left: 10px solid yellow;
      -webkit-transform: rotate(45deg); /* for Chrome || Safari */
      -moz-transform: rotate(45deg); /* for Firefox */
      -ms-transform: rotate(45deg); /* for IE */
      -o-transform: rotate(45deg);
      right: -10px;
      bottom: -10px;
      .el-icon-close {
        color: #fff;
        position: absolute;
        font-size: 8px;
        left: -12px;
        top: -6px;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
      }
    }
  }
  .blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
      border-left: 10px solid #2370eb;
    }
  }
  .yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
      border-left: 10px solid #fa8c15;
    }
  }
}
.but {
  width: 100%;
  height: 48px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: 72px;
  }
}
</style>
