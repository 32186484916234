<template>
    <div class="details">
        <div class="content_box">
            <div class="content">
                <p class="title"><span></span>工单内容</p>
                <div style="flex:1">
                    <Content
                        ref="content"
                        @close="onClose"
                        @addDealings="addDealings"
                    />
                </div>
                <div class="bottom-but">
                    <span style="margin-left: auto;">
                        <el-button
                            round
                            type="primary"
                            size="mini"
                            style="background:none;border-color:#D5D6DF;color:#666666;;margin-right:20px"
                            @click="onClose"
                            >取消</el-button
                        >
                        <el-button
                            v-if="
                                btnP.storage &&
                                    (data.status == 1 || data.status == 2)
                            "
                            round
                            type="primary"
                            size="mini"
                            style="background:none;border-color:#D5D6DF;color:#666666;;margin-right:20px"
                            @click="onUpData"
                            >暂时保存</el-button
                        >
                        <el-button
                            v-if="
                                btnP.complete &&
                                    (data.status == 1 || data.status == 2)
                            "
                            round
                            type="primary"
                            style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:20px;margin-left:0"
                            size="mini"
                            @click="onSubmit"
                            >处理完成</el-button
                        ></span
                    >
                </div>
            </div>
            <div class="content_list">
                <div class="tab-box">
                    <span></span>
                    <p
                        @click="onSwitch(1)"
                        :class="index == 1 ? 'isColor' : ''"
                    >
                        工单
                    </p>
                    <p
                        @click="onSwitch(2)"
                        :class="index == 2 ? 'isColor' : ''"
                    >
                        联系人信息
                    </p>
                    <p
                        @click="onSwitch(3)"
                        :class="index == 3 ? 'isColor' : ''"
                    >
                        往来
                    </p>

                    <p
                        @click="onSwitch(4)"
                        :class="index == 4 ? 'isColor' : ''"
                    >
                        历史记录
                    </p>
                </div>
                <div class="content-form-box">
                    <Order ref="order" v-show="index == 1" />
                    <Contacts
                        ref="contacts"
                        v-show="index == 2"
                        @getContacts="getContacts"
                    />
                    <Dealings ref="dealing" v-show="index == 3" />
                    <History ref="history" v-show="index == 4" />
                </div>
            </div>
        </div>
        <!-- 添加往来 -->
        <el-dialog
            :visible.sync="dialogDealings"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    添加往来
                </div>
            </template>
            <AddDealings @close="handleClose" ref="dealings" />
        </el-dialog>
    </div>
</template>
<script>
import Content from './content/content.vue';
import { workorderGet, workorderUpdate } from '@/api/cs/workOrder.js';
import Order from './order/order.vue';
import Contacts from './contacts/contacts.vue';
import Dealings from './dealings/dealings.vue';
import History from './history/history.vue';
import AddDealings from './dealings/addDealings.vue';
export default {
    components: {
        Content,
        Order,
        Contacts,
        Dealings,
        History,
        AddDealings,
    },
    data() {
        return {
            row: {},
            index: 1,
            data: {},
            btnP: {},
            dialogDealings: false,
        };
    },
    methods: {
        getData(row, btnP, type) {
            this.row = row;
            this.index = 1;
            // console.log(row, btnP, type);
            this.isDisabled = type;
            this.btnP = btnP;
            let data = {
                param: {
                    id: row.id,
                },
            };
            workorderGet(data).then((res) => {
                this.data = res.data;
                this.$refs.content.getData(res.data, btnP);
                this.$refs.order.getData(res.data);
            });
        },
        onSwitch(i) {
            this.index = i;
            if (i == 1) {
                this.$refs.order.getData(this.data);
            } else if (i == 2) {
                this.$refs.contacts.getData(this.data);
            } else if (i == 3) {
                this.$refs.dealing.getData(this.data, this.btnP);
            } else if (i == 4) {
                this.$refs.history.getData(this.data);
            }
        },
        getContacts(i) {
            this.index = i;
            let data = {
                param: {
                    id: this.row.id,
                },
            };
            workorderGet(data).then((res) => {
                this.data = res.data;
                this.$refs.contacts.getData(this.data);
            });
        },
        addDealings() {
            this.dialogDealings = true;
            setTimeout(() => {
                this.$refs.dealings.getData(this.data);
            });
        },
        handleClose() {
            this.dialogDealings = false;
            setTimeout(() => {
                this.$refs.dealing.getData(this.data, this.btnP);
            });
        },
        onUpData() {
            let data = {
                param: {
                    id: this.data.id,
                    handleOpinion: this.$refs.content.textarea,
                    priority: this.$refs.order.priority,
                    problemType: this.$refs.order.problemType,
                    status: 2,
                },
            };
            workorderUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                }
            });
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    handleOpinion: this.$refs.content.textarea,
                    priority: this.$refs.order.priority,
                    problemType: this.$refs.order.problemType,
                    status: 3,
                },
            };
            workorderUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                }
            });
        },

        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    height: 100%;
    background: #f0f2f4;
    .content_box {
        height: 100%;
        display: flex;
        .content {
            background: #fff;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            flex: 1;
            .title {
                height: 59px;
                border-bottom: 1px dashed #e5e5e5;
                display: flex;
                align-items: center;
                margin: 0 10px;
                font-size: 14px;
                color: #333333;
                span {
                    display: block;
                    width: 2px;
                    height: 16px;
                    background: #2370eb;
                    border-radius: 1px 1px 0px 0px;
                    margin-right: 12px;
                }
            }
        }
        .content_list {
            flex: 1;
            background: #fff;
            display: flex;
            flex-direction: column;
            .tab-box {
                display: flex;
                align-items: center;
                height: 59px;
                border-bottom: 1px dashed #eee;
                overflow: auto;
                margin: 0 10px;
                span {
                    display: block;
                    width: 2px;
                    height: 16px;
                    background: #2370eb;
                    border-radius: 1px 1px 0px 0px;
                    margin-right: 12px;
                }
                p {
                    cursor: pointer;
                    font-weight: 500;
                    color: #666666;
                    font-size: 14px;
                    padding: 0 16px;
                    height: 100%;
                    line-height: 59px;
                    overflow: hidden;
                    /*文本不会换行*/
                    white-space: nowrap;
                    /*当文本溢出包含元素时，以省略号表示超出的文本*/
                    text-overflow: ellipsis;
                }
                .isColor {
                    color: #2370eb;
                    position: relative;
                }
            }
            .content-form-box {
                overflow: auto;
                height: 100%;
                flex: 1;
            }
        }
    }
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e5e5;
    button {
        width: 90px;
    }
}
</style>
