<template>
    <div class="add-Mail">
        <div class="form">
            <el-form
                ref="form"
                :model="form"
                style="padding-left: 20px; margin-top: 20px"
                label-width="90px"
                label-position="left"
            >
                <el-form-item label="客户">
                    <el-select
                        v-model="form.customerId"
                        size="small"
                        style="width: 240px"
                        clearable
                        placeholder="请选择"
                        :disabled="!disabled"
                    >
                        <el-option
                            v-for="(item, index) in customerList"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <!-- <el-button
                        v-if="!disabled"
                        type="text"
                        style="color:#2370EB;margin-left:5px"
                        size="mini"
                        @click="onDetails(form.customerId)"
                        >查看</el-button
                    > -->
                </el-form-item>
                <el-form-item label="联系人" required>
                    <el-input
                        :disabled="!disabled"
                        v-model="form.contactName"
                        size="small"
                        style="width: 240px"
                        placeholder="请输入联系人"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" required>
                    <div
                        class="info contact_way_box"
                        v-for="(itm, index) in customerContactWayDOList"
                        :key="index"
                    >
                        <el-button
                            v-if="index > 0 && disabled"
                            type="primary"
                            @click="toTop(index)"
                            class="el-icon-top ordinary"
                            circle
                            size="mini"
                        ></el-button>
                        <el-select
                            :disabled="!disabled"
                            v-model="itm.contactWay"
                            size="small"
                            style="width: 90px"
                            clearable
                            placeholder="方式"
                        >
                            <el-option label="电话" value="1"></el-option>
                            <el-option label="微信" value="2"></el-option>
                            <el-option label="邮箱" value="3"></el-option>
                            <el-option label="QQ" value="4"></el-option>
                            <el-option label="短信" value="5"></el-option>
                            <el-option label="拜访" value="6"></el-option>
                            <el-option label="其他" value="7"></el-option>
                        </el-select>
                        <el-input
                            :disabled="!disabled"
                            style="margin-left: 10px; width: 140px"
                            v-model="itm.contactContent"
                            placeholder="请输入"
                            size="small"
                        ></el-input>
                        <el-button
                            v-if="index == 0 && disabled"
                            @click="addContact(index)"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0 && disabled"
                            @click.prevent="removeContact(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </div>
                </el-form-item>

                <el-form-item label="性别">
                    <el-radio-group v-model="form.sex" :disabled="!disabled">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="部门">
                    <el-input
                        :disabled="!disabled"
                        v-model="form.department"
                        size="small"
                        style="width: 240px"
                        placeholder="请输入部门"
                    ></el-input>
                </el-form-item>
                <el-form-item label="职务">
                    <el-input
                        size="small"
                        :disabled="!disabled"
                        style="width: 240px"
                        v-model="form.duty"
                        placeholder="请输入"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="生日">
                    <el-date-picker
                        :disabled="!disabled"
                        v-model="form.birthday"
                        type="date"
                        size="small"
                        style="width: 240px"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        :disabled="!disabled"
                        style="
                            width: 240px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-size: 14px;
                        "
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="form.remark"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but" v-show="disabled">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
        <!-- 客户详情 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    客户详情
                </div>
            </template>
            <Details @close="handleClose" ref="details" />
        </el-drawer>
    </div>
</template>
<script>
import { contactEdit } from '@/api/user/ae/customer';
import { customerList } from '@/api/customer/customer.js';
import { getCustomerId } from '@/api/user/ae/customer';
import Details from '../../../customer/details/details.vue';
export default {
    components: {
        Details,
    },
    data() {
        return {
            form: {},
            customerContactWayDOList: [{}],
            disabled: false,
            customerList: [],
            drawerDetails: false,
            direction: 'rtl',
        };
    },
    methods: {
        // 客户列表
        getCustomerList(row) {
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };
            data.param.adminId = sessionStorage.getItem('adminId');
            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerList = res.data.list;
                    this.getData(row);
                }
            });
        },
        // 数据回显
        getData(row) {
            // console.log(row);
            this.disabled = false;
            let data = row;
            this.form = row;
            if (this.form.customerBO && this.form.customerBO.id) {
                this.form.customerId = this.form.customerBO.id;
            }
            if (!this.form.customerBO || !this.form.customerBO.id) {
                this.form.customerId = '';
            }
            if (
                this.form.customerContactWayDOList &&
                this.form.customerContactWayDOList.length > 0
            ) {
                this.customerContactWayDOList =
                    this.form.customerContactWayDOList;
                for (let i in this.customerContactWayDOList) {
                    this.customerContactWayDOList[i].contactWay =
                        this.customerContactWayDOList[i].contactWay + '';
                }
            } else {
                this.customerContactWayDOList = [{}];
            }
        },
        getUpData(type) {
            this.disabled = type;
        },
        addContact() {
            this.customerContactWayDOList.push({});
        },
        removeContact(index) {
            this.customerContactWayDOList.splice(index, 1);
        },
        // 置顶
        toTop(index) {
            let arr = this.customerContactWayDOList.splice(index, 1);
            // console.log(arr);
            this.customerContactWayDOList.unshift(arr[0]);
        },

        onSubmit() {
            if (this.form.contactName == '') {
                return this.$message.error('请输入联系人');
            }
            this.form.customerContactWayDOList = this.customerContactWayDOList;
            for (let i in this.form.customerContactWayDOList) {
                if (
                    !this.form.customerContactWayDOList[i].contactWay ||
                    !this.form.customerContactWayDOList[i].contactContent
                ) {
                    return this.$message.error('请输入完整联系方式');
                }
            }
            let data = {
                param: {
                    customerContactDO: this.form,
                },
            };
            contactEdit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                }
                this.onClose();
            });
        },
        onClose() {
            this.$emit('close');
            this.$formDetails.commit('emptyData');
        },
        onDetails(id) {
            // console.log(id);
            let data = {
                param: {
                    id: id,
                },
            };
            getCustomerId(data).then((res) => {
                // console.log(res);
                let btnP = {};
                let type = false;
                this.drawerDetails = true;
                setTimeout(() => {
                    this.$refs.details.getData(res.data, btnP, type);
                }, 0);
                this.$formDetails.commit(
                    'detailsData',
                    JSON.stringify(res.data)
                );
                this.$choiceLabel.commit('getIsDetails', type);
                this.$choiceLabel.commit('getType', '2');
            });
        },
        handleClose() {
            this.drawerDetails = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add-Mail {
    height: 100%;
    display: flex;
    flex-direction: column;
    .form {
        flex: 1;
        overflow: auto;
    }
    .contact_way_box {
        background-color: #fff;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 0;
        margin-bottom: 10px;
        position: relative;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancel {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancel {
            border-left: 10px solid #fa8c15;
        }
    }
}

.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
</style>
