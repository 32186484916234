<template>
    <div class="contacts">
        <div class="contacts_list" v-for="item in data">
            <el-form
                label-width="90px"
                label-position="left"
                size="small"
                style="padding-top: 10px"
            >
                <el-form-item label="客户">
                    <div
                        style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <p>
                            <span
                                v-show="
                                    item.customerBO &&
                                    item.customerBO.companyName
                                "
                                >{{
                                    item.customerBO.companyName
                                        ? item.customerBO.companyName
                                        : '- -'
                                }}</span
                            >
                        </p>
                        <span>
                            <el-button
                                v-if="!item.customerBO.companyName"
                                @click="onRelation(item)"
                                type="primary"
                                size="mini"
                                style="
                                    background: none;
                                    norder-color: #2370eb;
                                    color: #2370eb;
                                "
                                >关联</el-button
                            >
                            <!-- <el-button
                                v-else
                                @click="onDetails(item.customerBO)"
                                type="primary"
                                size="mini"
                                style="background:none;norder-color:#2370EB;color:#2370EB"
                                >查看</el-button
                            > -->
                        </span>
                    </div>
                </el-form-item>
                <el-form-item label="联系人">
                    <span>{{ item.contactName }}</span>
                </el-form-item>
                <el-form-item label="联系方式">
                    <span v-if="item.customerContactWayDOList.length > 0">{{
                        item.customerContactWayDOList[0].contactContent
                    }}</span>
                    <span v-else>- -</span>
                </el-form-item>
                <el-form-item label="性别">
                    <span>{{
                        item.sex == 1 ? '男' : item.sex == 2 ? '女' : '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="部门">
                    <span>{{ item.department ? item.department : '- -' }}</span>
                </el-form-item>
                <el-form-item label="职位">
                    <span>{{ item.duty ? item.duty : '- -' }}</span>
                </el-form-item>
                <el-form-item label="生日">
                    <span>{{ item.birthday ? item.birthday : '- -' }}</span>
                </el-form-item>
                <el-form-item label="备注">
                    <span>{{ item.remark ? item.remark : '- -' }}</span>
                </el-form-item>
            </el-form>
        </div>
        <!-- 关联 -->
        <el-dialog
            :visible.sync="drawerRelation"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择联系人
                </div>
            </template>
            <Relation @close="handleClose" ref="relation" />
        </el-dialog>
        <!-- 客户详情 -->
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="1080px"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    客户详情
                </div>
            </template>
            <Details @close="handleClose" ref="details" />
        </el-drawer>
    </div>
</template>
<script>
import Relation from './relation.vue';
import Details from '../../../customer/customer/details/details.vue';
export default {
    components: {
        Relation,
        Details,
    },
    data() {
        return {
            data: [],
            drawerRelation: false,
            drawer: false,
            direction: 'rtl',
        };
    },
    methods: {
        getData(data) {
            this.detailsData = data;
            this.data = data.customerContactList;
        },
        onRelation(item) {
            this.drawerRelation = true;
            setTimeout(() => {
                this.$refs.relation.getCustomerList(item);
            });
        },
        handleClose() {
            this.drawerRelation = false;
            setTimeout(() => {
                this.$emit('getContacts', 2);
            });
        },
        onDetails(data) {
            this.drawer = true;
            setTimeout(() => {
                let btnP = {};
                this.$refs.details.getData(data, btnP, false);
            }, 0);
            this.$choiceLabel.commit('getIsDetails', false);
            this.$choiceLabel.commit('getType', '2');
        },
        handleClose() {
            this.drawer = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contacts {
    margin: 0 15px 0px 31px;
}
.contacts_list {
    border-bottom: 1px solid #e5e5e5;
}
</style>
