import http from '@/utils/http.js'

// 列表
export function contactList(data) {
    return http({
        url: "/jasoboss/customer/contact/list",
        method: "post",
        data
    })
}
// 联系人历史列表
export function historyList(data) {
    return http({
        url: "/jasoboss/customer/contact/history/list",
        method: "post",
        data
    })
}