<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex:1;margin-top:20px;margin-left: 40px;padding-bottom: 50px;"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备品牌">
                <span class="text">腾讯企业邮箱</span>
            </el-form-item>
            <el-form-item label="公司名称">
                <span class="text">{{ form.companyName }}</span>
                <span
                    @click="copyText(form.companyName)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <el-form-item label="邮箱版本">
                <span class="text">{{
                    form.emailVersionType == 1
                        ? '全新用户'
                        : form.emailVersionType == 2
                        ? '已有新版基础版'
                        : form.emailVersionType == 3
                        ? '已有旧版基础版'
                        : '- -'
                }}</span>
            </el-form-item>
            <el-form-item
                v-show="form.emailVersionType != ''"
                :label="
                    form.emailVersionType == 1
                        ? '手机号'
                        : form.emailVersionType == 2
                        ? '企业ID'
                        : form.emailVersionType == 3
                        ? '管理员账号'
                        : ''
                "
            >
                <span class="text">{{ form.emailVersionTypeName }}</span>
            </el-form-item>

            <el-form-item label="用户数">
                <span class="text">{{ form.number }}</span>
            </el-form-item>
            <el-form-item label="试用天数">
                <span class="text">{{ form.term }}</span>
            </el-form-item>
            <el-form-item label="备注">
                <span class="text">{{ form.remark }}</span>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left:300px"
                @click="onClose"
                >取消</el-button
            >
        </div>
    </div>
</template>
<script>
import { tmTrialUpdate } from '@/api/user/business/customer';
import { trialList } from '@/api/user/ae/tencent';
export default {
    data() {
        return {
            form: {},
            auditStatus: '1',
            rejectReason: '',
            accountInfo: '',
            id: '',
        };
    },
    methods: {
        echoData(data) {
            // console.log(data);
            this.auditStatus = '1';
            this.accountInfo = '';
            this.rejectReason = '';
            this.form = data;
            this.id = data.customerId;
        },

        onClose() {
            this.$emit('close');
            this.accountInfo = '';
            this.rejectReason = '';
        }, //复制
        copyText(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
