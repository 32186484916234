<template>
    <div class="contacts">
        <div class="contacts_list" v-for="itm in data">
            <el-form label-width="90px" label-position="left" size="small">
                <div class="text_box">
                    <span>
                        <span style="margin-right:10px">
                            {{ itm.contactName }} </span
                        ><span>{{ itm.customerBO.companyName }}</span>
                    </span>

                    <span>
                        <el-button
                            @click="onDetails(itm)"
                            type="primary"
                            style="background:#fff;color:#2370EB;border-color:#2370EB"
                            size="mini"
                            >查看</el-button
                        >
                        <el-button
                            v-show="itm.fold && itm.dealings.length > 0"
                            type="text"
                            @click="itm.fold = !itm.fold"
                            style="color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!itm.fold && itm.dealings.length > 0"
                            type="text"
                            @click="itm.fold = !itm.fold"
                            style="color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </span>
                </div>
                <el-form-item label="关联工单">
                    <div class="text_box">
                        <span>{{ itm.workOrderList.length }}条</span>
                        <span>
                            <el-button
                                type="primary"
                                style="background:#fff;color:#2370EB;border-color:#2370EB"
                                size="mini"
                                @click="onAddDealings(itm)"
                                >新增往来</el-button
                            >
                        </span>
                    </div>
                </el-form-item>

                <div
                    v-show="itm.fold"
                    v-for="(item, index) in itm.dealings"
                    class="Dealings_list"
                >
                    <el-button
                        type="primary"
                        size="mini"
                        style="background:none;border-color:#2370EB;color:#2370EB;;margin-bottom:16px;margin-top:16px"
                        @click="onAddExplain(index, item)"
                    >
                        <i class="el-icon-plus" style="margin-right:10px"></i
                        >添加说明
                    </el-button>
                    <div class="line">
                        <span>往来时间</span>
                        <span>{{ item.intercourseTime }}</span>
                    </div>
                    <div class="line">
                        <span>往来标题</span>
                        <span>{{ item.intercourseTitle }}</span>
                    </div>
                    <div class="line">
                        <span>跟进人</span>
                        <span>{{ item.adminName }} </span>
                    </div>
                    <div class="line">
                        <span>联系方式</span>
                        <span
                            >{{
                                item.contactWay == 1
                                    ? '电话'
                                    : item.contactWay == 2
                                    ? '微信'
                                    : item.contactWay == 3
                                    ? '邮箱'
                                    : item.contactWay == 4
                                    ? 'QQ'
                                    : item.contactWay == 5
                                    ? '短信'
                                    : item.contactWay == 6
                                    ? '拜访'
                                    : item.contactWay == 7
                                    ? '其他'
                                    : item.contactWay == 8
                                    ? '官网表单'
                                    : item.contactWay == 9
                                    ? '座机'
                                    : '- -'
                            }}
                        </span>
                    </div>
                    <div class="line">
                        <span>往来内容</span>
                        <span>{{ item.intercourseContent }}</span>
                    </div>
                    <div
                        class="describe"
                        v-for="(remark, index) in item.describeList"
                    >
                        <div>
                            <span>说明</span>
                            <span>{{ remark.describeContent }}</span>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <!-- 添加往来 -->
        <el-dialog
            :visible.sync="dialogDealings"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    添加往来
                </div>
            </template>
            <AddDealings ref="dealings" @close="handleClose" />
        </el-dialog>
        <!-- 说明 -->
        <el-dialog
            :visible.sync="dialogVisibles"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    添加说明
                </div>
            </template>
            <AddExplain ref="explain" @close="handleClose" />
        </el-dialog>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleDetails"
            size="964px"
            append-to-body
        >
            <template slot="title">
                <div style="display:flex; align-items: center;">
                    详情
                </div>
            </template>
            <Details @close="handleDetails" ref="details" />
        </el-drawer>
    </div>
</template>
<script>
import AddDealings from './addDealings.vue';
import AddExplain from '../../../contacts/details/dealings/addExplain.vue';
import Details from '../../../contacts/details/details.vue';
export default {
    components: {
        AddDealings,
        AddExplain,
        Details: (resolve) =>
            require(['../../../contacts/details/details.vue'], resolve),
    },
    data() {
        return {
            data: [],
            dialogDealings: false,
            dialogVisibles: false,
            direction: 'rtl',
            drawerDetails: false,
        };
    },
    methods: {
        getData(data) {
            // console.log(data);
            this.data = data;
        },
        onAddDealings(item) {
            this.dialogDealings = true;
            setTimeout(() => {
                this.$refs.dealings.getData(item);
            });
        },
        handleClose() {
            this.dialogDealings = false;
            this.dialogVisibles = false;
            this.$emit('close');
        },
        onAddExplain(i, item) {
            this.dialogVisibles = true;
            let that = this;
            setTimeout(() => {
                this.$refs.explain.getIntercourseId(item);
            }, 50);
        },
        // 详情
        onDetails(row) {
            this.drawerTitle = '联系人详情';
            let type = false;
            this.drawerDetails = true;
            let btnP = {};

            setTimeout(() => {
                this.$refs.details.getData(row, btnP, type);
            }, 10);
            this.$formDetails.commit('detailsData', JSON.stringify(row));
        },
        handleDetails() {
            this.drawerDetails = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contacts {
    width: 100%;
    .contacts_list {
        padding: 10px;
        margin: 8px 14px;
        background: #f3f5f7;
        border-radius: 8px;
        .text_box {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #333333 !important;
        }
    }
    .Dealings_list {
        margin-bottom: 10px;
        padding: 10px;
        background: #fff;
        border-radius: 8px;
        .line {
            padding-bottom: 16px;
            position: relative;
            span:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                display: inline-block;
                width: 60px;
            }
            span:nth-child(2) {
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                margin-left: 34px;
            }
        }
        .Circle {
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            background: #2370eb;
            border: 2px solid #2370eb;
            left: -30px;
            top: 6px;
            border-radius: 50%;
        }
        .describe {
            padding-bottom: 16px;
            span:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                display: inline-block;
                width: 60px;
            }
            span:nth-child(2) {
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                margin-left: 34px;
            }
        }
    }
}
</style>
