<template>
  <div class="order">
    <p class="title">
      <span>工单基本信息</span>
      <span>
        <el-button
          v-show="btnP.transfer && (data.status == 1 || data.status == 2)"
          type="primary"
          size="mini"
          style="background:none;norder-color:#2370EB;color:#2370EB"
          @click="onTransfer()"
          >转单</el-button
        >
      </span>
    </p>
    <el-form label-width="90px" label-position="left" size="small">
      <el-form-item label="工单标题">
        <span>{{ data.title }}</span>
      </el-form-item>
      <el-form-item label="工单号">
        <span>{{ data.workOrderNo }}</span>
      </el-form-item>
      <el-form-item label="创建时间">
        <span>{{ data.createTime }}</span>
      </el-form-item>
      <el-form-item label="创建人">
        <span>{{ data.adminName }}</span>
      </el-form-item>
      <el-form-item label="工单分类">
        <span>{{
          data.category == 1
            ? "管理员问题"
            : data.category == 2
            ? "成员问题"
            : data.category == 3
            ? "客户端问题"
            : "- -"
        }}</span>
      </el-form-item>
      <el-form-item label="工单状态">
        <span>{{
          data.status == 1
            ? "待处理"
            : data.status == 2
            ? "处理中"
            : data.status == 3
            ? "已处理"
            : data.status == 4
            ? "已关闭"
            : "- -"
        }}</span>
      </el-form-item>
      <el-form-item label="受理人">
        <span>{{ data.acceptAdminName }}</span>
      </el-form-item>
      <el-form-item label="优先级">
        <el-select
          v-model="priority"
          size="small"
          style="width:270px;"
          placeholder="请选择优先级"
          clearable
        >
          <el-option label="一般" value="1"></el-option>
          <el-option label="高" value="2"></el-option>
          <el-option label="低" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题类型">
        <el-select
          v-model="problemType"
          size="small"
          filterable
          style="width:270px;"
          clearable
          placeholder="请选择或输入关键字搜索"
        >
          <el-option
            v-for="item in problem"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 转单 -->
    <el-dialog
      :visible.sync="drawerTransfer"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          选择联系人
        </div>
      </template>
      <Transfer @close="handleClose" ref="transfer" />
    </el-dialog>
  </div>
</template>
<script>
import Transfer from "../../transfer/transfer.vue";
export default {
  components: {
    Transfer,
  },
  data() {
    return {
      btnP: {},
      data: {},
      priority: "",
      problem: [],
      problemType: "",
      drawerTransfer: false,
    };
  },
  methods: {
    getData(data) {
      var btnArr = JSON.parse(sessionStorage.getItem("isButArr"));
      let btn = {};
      for (let i in btnArr) {
        btn["" + btnArr[i].route] = true;
      }
      this.btnP = btn;
      this.data = data;
      if (data.category == 1) {
        this.problem = this.$csForm.adminProblem();
      } else if (data.category == 2) {
        this.problem = this.$csForm.userProblem();
      } else if (data.category == 3) {
        this.problem = this.$csForm.clientProblem();
      }
      this.priority = String(data.priority);
      this.problemType = data.problemType;
    },
    onTransfer() {
      this.drawerTransfer = true;
      setTimeout(() => {
        this.$refs.transfer.getUserList(this.data);
      });
    },
    handleClose() {
      this.drawerTransfer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.order {
  height: 100%;
  margin: 0 15px 0px 31px;
  .title {
    padding: 15px 0;
    font-size: 14px;
    color: #333333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
