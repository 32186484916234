<template>
  <div class="transfer">
    <el-form
      ref="form"
      :model="form"
      style="padding-left:43px;flex:1; margin-top: 50px;"
      label-width="100px"
      label-position="left"
    >
      <el-form-item label="选择转单人" required>
        <el-select
          style="width:270px"
          v-model="form.acceptAdminId"
          size="small"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in acceptList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="bottom-but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { roleList } from "@/api/customer/customer";
import { workorderUpdate } from "@/api/cs/workOrder.js";
export default {
  data() {
    return {
      form: {},
      acceptList: [],
    };
  },
  methods: {
    // 受理人
    getUserList(row) {
      this.data = row;
      let data = {
        param: {
          roleTypeList: [16],
        },
      };
      roleList(data).then((res) => {
        this.acceptList = res.data;
        for (let i in this.acceptList) {
          if (this.acceptList[i].id == this.data.acceptAdminId) {
            this.acceptList.splice(i, 1);
          }
        }
      });
    },
    onClose() {
      this.$emit("close");
      this.form = {};
    },
    onSubmit() {
      if (!this.form.acceptAdminId) {
        return this.$message.error("请选择转单人");
      }
      let data = {
        param: {
          id: this.data.id,
          acceptAdminId: this.form.acceptAdminId,
        },
      };
      workorderUpdate(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.onClose();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.transfer {
  display: flex;
  flex-direction: column;
  height: 300px;
}
.bottom-but {
  width: 100%;
  line-height: 48px;
  background: #fff;
  border-top: 1px solid #dee0e3;
  margin-top: 16px;
  text-align: right;
  button {
    width: 90px;
  }
}
</style>
