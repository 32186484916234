<template>
    <div class="details">
        <div class="information">
            <div class="title">
                <p><span></span>客户信息</p>
                <el-button
                    type="primary"
                    @click="onUpData"
                    size="mini"
                    v-show="type"
                    >编辑</el-button
                >
            </div>
            <div class="content">
                <Information ref="information" @close="onClose" />
            </div>
        </div>
        <div class="aggregate">
            <div class="tab-box">
                <div class="tab">
                    <span></span>
                    <p
                        @click="onSwitch(1)"
                        :class="index == 1 ? 'isColor' : ''"
                    >
                        工单
                    </p>
                    <p
                        @click="onSwitch(2)"
                        :class="index == 2 ? 'isColor' : ''"
                    >
                        历史
                    </p>

                    <p
                        @click="onSwitch(3)"
                        :class="index == 3 ? 'isColor' : ''"
                    >
                        联系人
                    </p>
                </div>
                <span></span>
            </div>
            <div class="content-form-box">
                <Order ref="order" v-show="index == 1" />
                <History ref="history" v-show="index == 2" />
                <Contacts
                    ref="contacts"
                    @close="onSwitch(3)"
                    v-show="index == 3"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { contactList } from '@/api/cs/contacts.js';
import { workorderDealings } from '@/api/cs/workOrder.js';
import Information from './information/information.vue';
import Order from './order/order.vue';
import History from './history/history.vue';
import Contacts from './contacts/contacts.vue';
export default {
    data() {
        return {
            row: '',
            btnP: {},
            type: false,
            index: 1,
        };
    },
    components: { Information, History, Order, Contacts },

    methods: {
        getData(row, btnP, type) {
            this.index = 1;
            this.btnP = btnP;
            this.type = type;
            this.row = row;
            setTimeout(() => {
                this.$refs.information.getData(row);
                this.$refs.order.getData(this.row);
            });
        },
        onUpData() {
            setTimeout(() => {
                this.$refs.information.getUpData(true);
            });
        },
        onSwitch(i) {
            this.index = i;
            if (i == 1) {
                this.$refs.order.getData(this.row);
            } else if (i == 2) {
                this.$refs.history.getData(this.row);
            } else if (i == 3) {
                let data = {
                    param: {
                        customerId: this.row.id,
                    },
                    pageNum: 0,
                    pageSize: 0,
                };
                contactList(data).then((res) => {
                    let row = [];
                    if (res.data.list.length > 0) {
                        for (let i in res.data.list) {
                            res.data.list[i].fold = true;
                            res.data.list[i].dealings = [];
                            let data = {
                                param: {
                                    contactId: res.data.list[i].id,
                                },
                            };
                            workorderDealings(data).then((ress) => {
                                // console.log(ress);
                                res.data.list[i].dealings = ress.data.list;
                            });
                        }
                    }
                    row = res.data.list;
                    // console.log(row);
                    this.$refs.contacts.getData(row);
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    width: 100%;
    height: 100%;
    display: flex;
    background: #f0f2f4;
    .information {
        width: 412px;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        .title {
            height: 59px;
            border-bottom: 1px dashed #e5e5e5;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            color: #333333;
            p {
                display: flex;
                align-items: center;
            }
            span {
                display: block;
                width: 2px;
                height: 16px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
                margin-right: 12px;
            }
            button {
                background: #fff;
                color: #ff760f;
                border-color: #ff760f;
                margin-right: 8px;
            }
        }
        .content {
            flex: 1;
            overflow: auto;
        }
    }
    .aggregate {
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .tab-box {
            display: flex;
            align-items: center;
            height: 59px;
            border-bottom: 1px dashed #eee;
            overflow: auto;
            flex-direction: row;
            justify-content: space-between;
            .tab {
                display: flex;
                align-items: center;
                span {
                    display: block;
                    width: 2px;
                    height: 16px;
                    background: #2370eb;
                    border-radius: 1px 1px 0px 0px;
                    margin-right: 12px;
                }
                p {
                    cursor: pointer;
                    font-weight: 500;
                    color: #666666;
                    font-size: 14px;
                    padding: 0 16px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    overflow: hidden;
                    width: 60px;
                    border: 1px solid #e5e5e5;
                    border-radius: 12px;
                    /*文本不会换行*/
                    white-space: nowrap;
                    /*当文本溢出包含元素时，以省略号表示超出的文本*/
                    text-overflow: ellipsis;
                    margin-right: 10px;
                }
                .isColor {
                    color: #2370eb;
                    position: relative;
                    border-color: #2370eb;
                }
            }
            button {
                background: #fff;
                color: #2370eb;
                border-color: #2370eb;
                margin-right: 8px;
            }
        }
        .content-form-box {
            flex: 1;
            overflow: auto;
        }
    }
}
</style>
