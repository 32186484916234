import http from '@/utils/http.js'

// 列表
export function workorderList(data) {
    return http({
        url: "/jasoboss/work/order/list",
        method: "post",
        data
    })
}
// 关联联系人列表
export function contactList(data) {
    return http({
        url: "/jasoboss/customer/contact/list",
        method: "post",
        data
    })
}
// 添加
export function workorderAdd(data) {
    return http({
        url: "/jasoboss/work/order/add",
        method: "post",
        data
    })
}
// 修改
export function workorderUpdate(data) {
    return http({
        url: "/jasoboss/work/order/update",
        method: "post",
        data
    })
}
// 获取单个工单
export function workorderGet(data) {
    return http({
        url: "/jasoboss/work/order/get",
        method: "post",
        data
    })
}
// 客户联系人往来列表
export function workorderDealings(data) {
    return http({
        url: "/jasoboss/customer/contact/intercourse/list",
        method: "post",
        data
    })
}
// 客户联系人往来添加
export function addDealings(data) {
    return http({
        url: "/jasoboss/customer/contact/intercourse/add",
        method: "post",
        data
    })
}
// 客户联系人往来添加说明
export function addDescribe(data) {
    return http({
        url: "/jasoboss/customer/contact/intercourse/describe/add",
        method: "post",
        data
    })
}
// 工单历史列表
export function historyList(data) {
    return http({
        url: "/jasoboss/work/order/history/list",
        method: "post",
        data
    })
}
// 工单历史列表
export function orderList(data) {
    return http({
        url: "/jasoboss/order/list",
        method: "post",
        data
    })
}
