<template>
  <div class="history">
    <div v-for="item in data" class="list">
      <span></span>
      <span>{{ item.createTime }}</span>
      <p>{{ handle(item) }}</p>
    </div>
  </div>
</template>
<script>
import { historyList } from "@/api/cs/workOrder.js";
export default {
  data() {
    return {
      data: [],
    };
  },

  methods: {
    // 获取客户历史
    getData() {
      let data = {
        param: {
          workOrderId: this.$formDetails.state.data.id,
        },
      };
      historyList(data).then((res) => {
        this.data = res.data.list;
      });
    },
    handle(item) {
      let str = item.content;
      let text = str
        .replace("%s", item.firstAdminName ? item.firstAdminName : "", 1)
        .replace("%s", item.secondAdminName ? item.secondAdminName : "", 2);
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.history {
  height: 100%;
  overflow: auto;
  .list {
    padding: 30px 30px 0 30px;
    padding-left: 10px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    span:nth-child(1) {
      display: inline-block;
      width: 8px;
      height: 6px;
      background: #2370eb;
      border: 2px solid #2370eb;
      border-radius: 8px;
      margin-right: 16px;
    }
    p {
      margin-left: 32px;
      width: 80%;
    }
  }
}
</style>
