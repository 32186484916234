<template>
    <div style="height:100%">
        <el-table
            style="flex:1"
            ref="table"
            highlight-current-row
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="workOrderNo" label="工单号" show-overflow-tooltip>
            </el-table-column>

            <el-table-column
                prop="adminName"
                label="创建人"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="contractB" label="工单类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.category == 1
                            ? '管理员问题'
                            : scope.row.category == 2
                            ? '成员问题'
                            : scope.row.category == 3
                            ? '客户端问题'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="contractB" label="工单状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '待处理'
                            : scope.row.status == 2
                            ? '处理中'
                            : scope.row.status == 3
                            ? '已处理'
                            : scope.row.status == 4
                            ? '已关闭'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="acceptAdminName"
                label="受理人"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="contractB" label="操作" show-overflow-tooltip fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" style="color:#2370EB" @click="onDetails(scope.row)">
                        详情</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleDetails"
            size="880px"
            append-to-body
        >
            <template slot="title">
                <div style="display:flex; align-items: center;">
                    {{ drawerTitle }}
                </div>
            </template>
            <Details ref="detail" @close="handleDetails" />
        </el-drawer>
    </div>
</template>
<script>
import { workorderList } from '@/api/cs/workOrder.js';
import Details from '../../../../workOrder/details/details.vue';
export default {
    components: {
        Details,
    },
    data() {
        return {
            tableData: [],
            drawerDetails: false,
            direction: 'rtl',
            drawerTitle: '',
        };
    },
    methods: {
        getData(row) {
            let data = {
                param: {
                    customerId: row.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            workorderList(data).then((res) => {
                this.tableData = res.data.list;
            });
        },
        // 详情
        onDetails(row) {
            let type = false;
            this.drawerTitle = '工单详情';
            var btnP = {};
            this.drawerDetails = true;
            setTimeout(() => {
                this.$refs.detail.getData(row, btnP, type);
            }, 100);
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getIsDetails', type);
            this.$choiceLabel.commit('getType', '2');
        },
        handleDetails() {
            this.drawerDetails = false;
        },
    },
};
</script>
